// App.js
import React, { useState } from 'react';
import './App.css';
import LogoCompleto from './assets/LogoPuntos';

function App() {
  
  return (
    <div className="App">
      <header className="App-header">
        <LogoCompleto />
        <h1>Estamos construyendo</h1>
        <p className="description">
          algo increíble. ¡Espéralo pronto!
        </p>
       
      </header>
      <footer className="footer">
        © 2024 Mundo Tecnológico - Todos los derechos reservados
      </footer>
    </div>
  );
}

export default App;
